/* styles.css */
.textFieldRoot {
  margin-top: 10px;
}

.textFieldRoot .MuiInputLabel-root {
  padding: 0 4px;
  left: -4px;
  top: -10px;
  font-weight: 300;
}

.textFieldRoot .MuiInputLabel-shrink {
  top: 0 !important;
}

.textFieldRoot .MuiOutlinedInput-root {
  padding: 0px !important;
}

.textFieldRoot .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 10px !important;
  line-height: 35px !important;
}

.textFieldRoot .MuiOutlinedInput-root textarea {
  padding: 10px;
}

.textFieldRoot .MuiOutlinedInput-input {
  padding: 0px 10px;
  height: 35px !important;
  border-radius: 10px !important;
  background-color: #ffffff; /* Adjust color if needed */
  font-size: 12px;
  line-height: 17px;
}

.textFieldRoot .MuiTextField-root fieldset {
  border-color: #d3d3d3; /* Adjust color if needed */
  border-radius: 5px;
}

/* dropdownStyles.css */

.dropdownRoot {
  /* margin-top: 10px; */
}

.dropdownRoot .MuiInputLabel-root {
  padding: 0 4px;
  left: -4px;
  top: -10px;
  font-weight: 300;
}

.dropdownRoot .MuiChip-label {
  line-height: 30px;
}

.dropdownRoot .MuiInputLabel-shrink {
  top: 0 !important;
}

.dropdownRoot .MuiOutlinedInput-input {
  padding: 0px 10px;
  height: 35px !important;
  border-radius: 8px !important;
  font-size: 12px;
  line-height: 17px;
  background-color: #ffffff; /* Replace with theme color if needed */
}

.dropdownRoot .MuiOutlinedInput-root {
  padding: 11px;
  gap: 5px;
  min-height: 91px;
  align-items: flex-start;
}

.dropdownRoot .MuiOutlinedInput-root .MuiAutocomplete-popupIndicator {
  display: none;
}

.dropdownRoot .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 10px !important;
}

.dropdownRoot .MuiSvgIcon-root {
  fill: #203C6E;
}

.dropdownRoot .MuiTextField-root fieldset {
  border-color: #d3d3d3; /* Replace with theme color if needed */
  border-radius: 5px;
}

.dropdownRoot .MuiAutocomplete-tag {
  margin: 0px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  color: #000000;
  background-color: #f0f0f0; /* Replace with theme color if needed */
}

.dropdownRoot .MuiAutocomplete-tag .MuiChip-deleteIcon {
  font-size: 28px;
  fill: #6885AA;
  margin-right: 1px;
  position: relative;
}

.dropdownRoot .MuiAutocomplete-tag::after {
  content: "✕";
  width: 18px;
  height: 18px;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 10;
  background-color: #6885aa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  pointer-events: none;
  line-height: 18px;
}

.dropdownRoot .MuiInputBase-root fieldset {
  border-color: #d3d3d3; /* Replace with theme color if needed */
  border-radius: 5px;
}

/* General Styles */
.companySlider {
  color: #EDF2F5 !important;
  width: 98.5% !important;
  margin-left: 5px;
  margin-right: 5px;
}

/* Form Helper Text */
.companySlider .MuiFormHelperText-root {
  margin-left: 1px;
}

/* Slider Value Label */
.companySlider .MuiSlider-valueLabel {
  display: none !important;
}

/* Slider Mark Labels */
.companySlider .MuiSlider-markLabel {
  color: #000000;
  text-transform: capitalize;
  font-size: 13px;
  font-family: 'poppins-500', sans-serif;
  opacity: 70%;
}

.companySlider .MuiSlider-markLabel:first-child {
  margin-top: 10px !important;
}

/* Slider Rail */
.companySlider .MuiSlider-rail {
  height: 6px !important;
  background-color: #EDF2F5 !important;
  opacity: 50%;
}

/* Slider Track */
.companySlider .MuiSlider-track {
  height: 6px !important;
  background-color: #EDF2F5 !important;
  border: 0px !important;
}

/* Slider Mark */
.companySlider .MuiSlider-mark {
  background-color: transparent;
}

/* Slider Thumb */
.companySlider .MuiSlider-thumb {
  background-color: #EDF2F5 !important;
  border: 3px solid #EDF2F5 !important;
  width: 18px !important;
  height: 18px !important;
}

/* Specific Mark Labels by Index */
.companySlider .MuiSlider-markLabel[data-index='0'] {
  transform: translateX(0%) !important;
}

.companySlider .MuiSlider-markLabel[data-index='3'] {
  transform: translateX(-98%) !important;
}

/* Container margin top */
.dropdown-container {
  margin-top: 10px;
}

.size-dropdown .MuiOutlinedInput-root {
  padding: 0 10px !important;
}

/* Input label styles */
.dropdown-container .MuiInputLabel-root {
  padding: 0 4px;
  left: -4px;
  top: -10px;
  font-weight: 300;
}

/* Chip label styles */
.dropdown-container .MuiChip-label {
  line-height: 30px;
}

/* Label shrink state */
.dropdown-container .MuiInputLabel-shrink {
  top: 0 !important;
}

/* Outlined input styles */
.dropdown-container .MuiOutlinedInput-input {
  padding: 0px 10px;
  height: 35px !important;
  border-radius: 5px !important;
  font-size: 12px;
  line-height: 17px;
  color: #3C3C3C;
  background-color: #ffffff;
}

/* Outlined input root styles */
.dropdown-container .MuiOutlinedInput-root {
  padding: 0px;
}

.industry-dropdown .MuiOutlinedInput-root {
  padding: 0px 10px !important;
}

.mapfield-dropdown .MuiOutlinedInput-root {
  padding: 0px 10px !important;
  background-color: #ffffff;
}

/* Hide popup indicator */
.dropdown-container .MuiOutlinedInput-root .MuiAutocomplete-popupIndicator {
  display: none;
}

/* Autocomplete input styles */
.dropdown-container .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 10px !important;
}

/* SVG icon styles */
.dropdown-container .MuiSvgIcon-root {
  fill: #203C6E;
}

/* TextField fieldset styles */
.dropdown-container .MuiTextField-root fieldset {
  border-color: var(--field-border-color);
  border-radius: 5px;
}

/* Autocomplete tag styles */
.dropdown-container .MuiAutocomplete-tag {
  margin: 0px;
  height: 30px;
  border-radius: 5px;
  font-size: 15px;
  color: #000000;
  background-color: var(--blue-gray);
}

/* Autocomplete tag delete icon styles */
.dropdown-container .MuiAutocomplete-tag .MuiChip-deleteIcon {
  font-size: 28px;
  fill: #6885AA;
  margin-right: 1px;
  position: relative;
}

/* Custom 'X' button styles for tags */
.dropdown-container .MuiAutocomplete-tag::after {
  content: "✕";
  width: 15px;
  height: 15px;
  position: absolute;
  right: 7.5px;
  top: 8.9px;
  z-index: 100;
  background-color: #6885aa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  pointer-events: none;
}

/* InputBase fieldset styles */
.dropdown-container .MuiInputBase-root fieldset {
  border-color: "#D3D3D3";
  border-radius: 5px;
}

.mapfield-dropdown .MuiOutlinedInput-input {
  padding: 0px 10px !important;
  height: 35px !important;
  border-radius: 5px !important;
  font-size: 14px;
  line-height: 17px;
  color: #3C3C3C;
  background-color: #ffffff;
}

/* Container styles for the component */
.editor-container {
  height: 100%;
  margin-top: 10px;
}

/* Styles for the toolbar within the editor */
.editor-container .ql-toolbar {
  border-bottom: none !important;
  background-color: #f0f0f0;
  height: 35px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

/* Styles for the toolbar with 'ql-snow' class */
.editor-container .ql-toolbar.ql-snow {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

/* Header toolbar styles */
.editor-container .ql-toolbar-h {
  background-color: rgba(0, 0, 0, 0.08);
  font-size: 13px !important;
  padding-top: 9px;
  padding-bottom: 10px;
}

/* Styles for the editor area */
.editor-container .ql-editor {
  min-height: 155px !important;
  max-height: 155px !important;
  font-size: 12px !important;
  background-color: transparent !important;
}

.editor-container .ql-toolbar.ql-snow button, .editor-container .ql-toolbar .ql-icon-picker {
  background-color: #ffffff;
  border-radius: 10px !important;
  width: 24px;
}

.editor-container .ql-toolbar .ql-picker {
  background-color: #ffffff;
  border-radius: 10px !important;
}

.editor-container .ql-picker-label {
  border-radius: 10px !important;
}

.editor-container .ql-formats {
  margin-right: 0px !important;
  gap: 5px;
  display: flex;
}

.editor-container .ql-formats .ql-image, .editor-container .ql-formats .ql-video {
  display: none !important;
}

.editor-container .MuiBox-root {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

/* Additional styles for MUI Box component */
.editor-container.MuiBox-root {
  border-radius: 5px;
  border: 1px solid #D3D3D3;
}

/* Container styles for text fields */
.text-field-container {
  /* margin-top: 10px; */
}

/* Input label styles */
.text-field-container .MuiInputLabel-root {
  padding: 0 4px;
  left: -4px;
  top: -10px;
  font-weight: 300;
}

/* Styles for the input label when it is shrunk (e.g., when focused) */
.text-field-container .MuiInputLabel-shrink {
  top: 0 !important;
}

/* Outlined input root styles */
.text-field-container .MuiOutlinedInput-root {
  padding: 0px !important;
}

/* Styles for autocomplete input */
.text-field-container .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 10px !important;
  line-height: 35px !important;
}

/* Styles for textarea inside OutlinedInput */
.text-field-container .MuiOutlinedInput-root textarea {
  padding: 10px;
}

/* Outlined input styles */
.text-field-container .MuiOutlinedInput-input {
  padding: 0px 10px;
  height: 35px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
  font-size: 12px;
  line-height: 17px;
}

/* Fieldset styles for text fields */
.text-field-container.MuiTextField-root fieldset {
  border-color: #D3D3D3;
  border-radius: 5px;
}

/* Container styles for the checkbox component */
.checkbox-container {
  white-space: nowrap;
}

/* Styles for the Checkbox component */
.checkbox-container .MuiCheckbox-root {
  padding: 0px;
  padding-right: 5px;
  color: #5b5b5b;
}

/* Styles for the SVG icon within the Checkbox */
.checkbox-container .MuiCheckbox-root svg {
  width: 22px;
  height: 22px;
}

/* Styles for checked state of the Checkbox */
.checkbox-container .MuiCheckbox-root.Mui-checked {
  color: #0131FF;
}

/* Styles for the FormControlLabel label */
.checkbox-container .MuiFormControlLabel-label {
  font-size: 13px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  color: #172746;
}

/* Styles for the FormControlLabel root */
.checkbox-container .MuiFormControlLabel-root {
  margin-left: 0px;
  margin-bottom: 10px !important;
  white-space: break-spaces;
  align-items: flex-start;
}

/* Styles for the FormGroup component */
.checkbox-container .MuiFormGroup-root {
  margin: 0px;
}

/* General styles for the text field container */
.other-text-field-container {
  height: 35px;
}

/* Styles for the input inside the OutlinedInput component */
.other-text-field-container .MuiOutlinedInput-input {
  padding: 6.5px 14px;
  font-size: 15px;
  color: #000000;
}

/* Placeholder styles for the input */
.other-text-field-container .MuiOutlinedInput-input::placeholder {
  color: #000000;
}

/* Styles for the fieldset of the OutlinedInput */
.other-text-field-container fieldset {
  border-radius: 5px;
  border-color: #d3d3d3;
}

/* Styles for the Slider root */
.slider-container .MuiSlider-root {
  width: 100% !important;
}

/* Hide the value label on the Slider */
.slider-container .MuiSlider-valueLabel {
  display: none !important;
}

/* Styles for the Slider mark labels */
.slider-container .MuiSlider-markLabel {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  opacity: 1;
  color: #000000;
}

/* Margin for the first mark label */
.slider-container .MuiSlider-markLabel:first-child {
  margin-top: 10px !important;
}

/* Styles for the Slider rail */
.slider-container .MuiSlider-rail {
  height: 8.5px !important;
  background-color: #EDF2F5;
}

/* Styles for the Slider track */
.slider-container .MuiSlider-track {
  height: 8.5px !important;
  background-color: #7281A4;
  border: 0px !important;
}

/* Styles for the Slider marks */
.slider-container .MuiSlider-mark {
  background-color: transparent;
}

/* Styles for the Slider thumb */
.slider-container .MuiSlider-thumb {
  background-color: #0031FF;
  border: 3px solid #0031FF;
  width: 18px !important;
  height: 18px !important;
}

/* Transform styles for specific mark labels */
.slider-container .MuiSlider-markLabel[data-index='0'] {
  transform: translateX(0%) !important;
}

.slider-container .MuiSlider-markLabel[data-index='4'] {
  transform: translateX(-98%) !important;
}

.countText {
   width: 25px; height: 25px; min-width: 25px; line-height: 25px; border-radius: 50%; border: 1px solid gray; display: flex; align-items: center; justify-content: center; margin-right: 12px; font-family: 'Poppins-400';
}

.requirment-title {
  font-size: 16px !important;
  font-family: 'Poppins-600' !important;
  line-height: 20px !important;
  padding-top: 4px !important;
}

.requirment-desc {
  font-size: 14px !important;
  font-family: 'Poppins-400' !important;
  line-height: 20px !important;
}

.requirment-download-btn {
  font-size: 14px !important;
  font-family: 'Poppins-500' !important;
  line-height: 20px !important;
  padding: 0px !important;
  text-transform: capitalize !important;
  display: contents;
}

.requirment-btn {
  font-size: 14px !important;
  text-transform: none !important;
  color: #000000 !important;
  letter-spacing: 0.3px !important;
  font-family: 'Poppins-400' !important;
  line-height: 16px !important;
}

.mapfield-btn {
  font-size: 12px !important;
  text-transform: none !important;
  color: #000000 !important;
  letter-spacing: 0.3px !important;
  font-family: 'Poppins-400' !important;
  line-height: 16px !important;
}