.pac-container {
    border-radius: 8px;
}

.pac-icon {
    display: none;
}

.pac-item {
    height: 50px;
    padding: 10px;
}
