.event-card-widget {
    padding: 16px;
}

.event-card-widget .event-date-time {
    color: #203C6E;
}

.event-card-widget .event-title {
    font-weight: 500px;
}

.event-card-widget .event-title,
.event-card-widget .event-card-widget-text .event-subtitle {
    color: #172746;
}

.event-card-widget .event-card-bulets .bullets {
    font-size: 0px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 1px solid #7283A4;
    margin-right: 5px;
}

.event-card-widget .event-card-bulets .bullets:last-child {
    margin-right: 0px;
}

.event-card-widget .event-card-bulets .bullets.active {
    background-color: #7283A4;
}
