@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* TODO: Somewhere we are setting the background color and its not 
    covering the whole page. Find the culprit and fix it. Setting
    bg color on body. Check tailwind config.
   */

/**
Default
*/
  input:-webkit-autocomplete {
    -webkit-box-shadow: 0 0 0 1000px rgb(195, 10, 10) inset !important;
  }

  .create-post-card {
    border-radius: 12px;
    border: 1px solid #dee1e3;
  }

  .event-post-card {
    border-radius: 8px;
    border: 1px solid #dee1e3;
  }

  .card-shadow {
    /* box-shadow: rgba(0 0 0 / 30%) 0px 0px 1px; */
    border-radius: 15px;
    border: 1px solid #dee1e3;
  }

  .connect-card {
    box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 4px;
    border-radius: 10px;
  }

  .event-card-shadow {
    box-shadow: rgba(0 0 0 / 10%) 1px 1px 4px;
  }

  .profile-match-shadow {
    box-shadow: rgba(0 0 0 / 10%) 0px 0px 10px;
  }

  .post-company-logo {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  }

  .profile-avatar-shadow {
    box-shadow: rgba(0 0 0 / 16%) 0px 3px 6px;
  }

  .dropdown-box {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    border-radius: 5px;
  }

  .card-title-shadow {
    box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 6px;
  }

  .highlight-shadow {
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 10px;
  }

  .mutuals-shadow {
    box-shadow: rgba(145, 145, 145, 0.20) 0px 0px 10px;
    border-radius: 20px;
  }

  .landing-nav-shadow {
    box-shadow: rgba(213, 217, 226, 1) 0px 5px 15px;
  }

  .contact-shadow {
    box-shadow: rgba(0 0 0 / 10%) 0px 5px 10px;
  }

  .see-all-button {
    box-shadow: rgba(0 0 0 / 9%) 0px 0px 6px;
  }

  .event-website-box {
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 20px;
    border-radius: 20px;
    /* background-color: #F5F8F9; */
    background-color: rgba(245, 248, 249, 1);
  }
  .event-afterhours-box {
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 20px;
    border-radius: 20px;
    /* background-color: #F5F8F9; */
    background-color: rgba(245, 248, 249, 10%);
  }

  .event-website-box-new {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 17px;
    border-radius: 8px;
    /* background-color: #F5F8F9; */
    background-color: rgba(245, 248, 249, 1);
  }

  .b-nav-shadow {
    box-shadow: -6px 0px 8px 0px rgb(61 61 61 / 32%);
  }

  .create-menu-dropdown {
    height: calc(100vh - 54px);
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .create-menu-dropdown::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .company-logo-shadow {
    box-shadow: rgba(0 0 0 / 16%) 0px 0px 5px;
  }

  .large-radius {
    border-radius: 20px;
  }

  .medium-radius {
    border-radius: 8px;
  }

  .medium-radius-7 {
    border-radius: 7px;
  }

  .small-radius {
    border-radius: 5px;
  }

  .small-radius-4 {
    border-radius: 4px;
  }

  .x-small-radius {
    border-radius: 8px;
  }


/********************************************/
/*********** Scrollbar Custom Style ***********/
/********************************************/
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E8EBED;
}

/********************************************/
/*********** Toaster Custom Style ***********/
/********************************************/
.Toastify .Toastify__toast {
  box-shadow: rgba(0 0 0 / 10%) 0px 3px 6px !important;
  border-radius: 10px !important;
  padding: 12px 10px;
  border: 0.5px solid #d9d9d9;
  max-width: 296px;
}

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #203C6E;
    border-radius: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #244E93;
  }

  /********************************************/
  /*********** Toaster Custom Style ***********/
  /********************************************/
  .Toastify .Toastify__toast {
    box-shadow: rgba(0 0 0 / 10%) 0px 3px 6px !important;
    border-radius: 10px !important;
    padding: 12px 10px;
    border: 0.5px solid #d9d9d9;
    max-width: 296px;
  }

  .Toastify .Toastify__toast .Toastify__toast-body {
    padding: 0;
    align-items: flex-start;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #3C3C3C;
    margin: 0;
    font-family: 'Poppins';
    max-width: 245px;
  }

  .Toastify .Toastify__toast svg {
    margin-bottom: 0;
  }

  .Toastify .Toastify__close-button {
    background-color: #EDF2F5;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -3px;
    top: -4px;
  }

  .Toastify .Toastify__close-button:hover {
    background-color: #d3eeff;
  }

  .Toastify .Toastify__close-button svg {
    color: #203C6E;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 5px;
    top: 3.8px;
  }

  .Toastify .Toastify__toast-icon {
    width: 16.7px !important;
    height: 16.7px !important;
    margin-right: 8px;
  }

  .Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon {
    width: 17px !important;
    height: 17px !important;
    margin-right: 8px;
    border-radius: 100%;
    background-image: url(../../panelist-react/public/images/toast-tick.png);
    background-repeat: no-repeat;
    background-position: 0px 0px;
    margin-top: 1px;
  }

  .Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon svg {
    display: none;
  }

  /********************************************/
  /********************************************/
  body {
    --tw-bg-opacity: 1;
    background-color: rgb(237 242 245 / var(--tw-bg-opacity));
  }

  input:checked+svg {
    display: block;
  }

  .t-elipsis-sm {
    white-space: nowrap;
    min-width: 10px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .t-elipsis-xs {
    white-space: nowrap;
    min-width: 50px;
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* This is to remove arrow in select - feedback from josh 12/05  */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  select {
    /* Reset Select */
    appearance: none;
    outline: 0;
    border: 0;
    box-shadow: none;
    /* Personalize */
    flex: 1;
    padding: 0 1em;
    color: #000;
    background-color: var(--darkgray);
    background-image: none;
    cursor: pointer;
  }

  /* Remove IE arrow */
  select::-ms-expand {
    display: none;
  }

  /* Custom Select wrapper */
  .select {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    border-radius: .25em;
    overflow: hidden;
  }

  .dropdown-btn {
    width: 194px;
    height: 30px;
    font-weight: 200;
    font-size: 13px;
  }

  .dropdown-icon {
    @apply w-4 h-3 absolute top-0 right-0 mx-4 pointer-events-none;
    margin-top: 0.6rem;
  }

  li::marker {
    color: #1d2a51 !important;
    font-size: 14px;
  }

  .platform-card {
    width: 576px;
    height: 412px !important;
  }

  .border-b-05 {
    border-bottom: 0.5px solid #203c6e;
  }

  .full-screen {
    background-size: cover;
    background-position: top;
    min-height: 100%;
    height: 100vh;
    position: relative;
  }

  .gradient-bg {
    background: url('./assets/images/website-event/gradient-bg.svg') center;
    background-position: top;
    background-size: contain;
  }

  .event-speaker-cover {
    background: url('./assets/images/website-event/avatar-event-speaker.svg') center;
    border-radius: 48px;
    border: 1px solid #285cb2;
    width: 96px;
    height: 96px;
  }

  .event-speaker-cover-two {
    background: url('./assets/images/website-event/avatar-agenda.svg') center;
    border-radius: 48px;
    border: 1px solid #285cb2;
    width: 45px;
    height: 45px;
  }


  .event-speaker-location {
    background: url('./assets/images/website-event/map-location.svg') center;
    background-color: gray;
    border-radius: 10px;
    min-height: 286px;
  }

  .event-partner-cover {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 5px;
    width: 85px;
    height: 85px;
  }

  .event-partner-quicklinks {
    border-radius: 20px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 5px;
    width: 85px;
    height: 85px;
  }

  .event-partner-cover-sm {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px;
    width: 41px;
    height: 41px;
  }

  .event-partner-cover-logo {
    background: url('./assets/images/website-event/microsoft-sm-icon.svg') center;
    /* background: gray; */
    width: 54px;
    height: 54px;
  }

.website-event-cover-spectre {
  background: url('./assets/images/website-event/website-event-bg.svg') center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 740px;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  /* margin-bottom: 50px; */
}

.agenda-card-spectre {
  display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 25px;
    /* grid-row-gap: 25px; */
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
}

.agenda-card-spectre-new {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  width: 100%;
}

.border-04 {
  border-width: 0.4px;
}
  .event-partner-cover-logo-sm {
    background: url('./assets/images/website-event/microsoft-sm-icon.svg') center;
    /* background: gray; */
    width: 25px;
    height: 25px;
  }

  .event-partner-cover-logo2 {
    background: url('./assets/images/website-event/microsoft-sm-icon.svg') center;
    /* background: gray; */
    width: 25px;
    height: 25px;
  }

  .website-event-cover {
    background: url('./assets/images/website-event/website-event-bg.svg') center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 638px;
    width: 100%;
    max-width: 1317px;
    margin: 0 auto;
    margin-bottom: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .website-event-cover-new {
    background: url('./assets/images/website-event/website-event-bg.svg') center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 860px;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    /* margin-bottom: 50px; */
  }

  .border-04 {
    border-width: 0.4px;
  }

  /***********************************************
    Feed and Media background blur css
  ************************************************/

  .feed-bg-blur {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-position: center;
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
    transform: translate(var(--tw-translate-x),
    var(--tw-translate-y))
    rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x))
    skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
    --tw-blur: blur(16px);
    filter: var(--tw-blur)
    var(--tw-brightness)
    var(--tw-contrast)
    var(--tw-grayscale)
    var(--tw-hue-rotate)
    var(--tw-invert)
    var(--tw-saturate)
    var(--tw-sepia)
    var(--tw-drop-shadow);
  }

  .feed-media-size {
    width: 100%;
    padding-top: 0px;
    max-height: 24rem/* 384px */;
    max-height: 400px;
    object-fit: contain;
    position: relative;
  }

  .feed-media-size-new {
    width: 100%;
    padding-top: 0px;
    /* max-height: 24rem; 384px; */
    height: 146px !important;
    object-fit: contain;
    position: relative;
  }

  .feed-card {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .feed-media-max-w {
    max-width: 656px;
  }
  .feed-media-max-w-h-new {
    max-width: 656px;
    height: 146px;
  }

  .spotlight-card-media {
    width: 100%;
    height: 100%;
    position: relative;
    object-fit: contain;
  }

  /***********************************************
    Home page and landing page CSS
  ************************************************/
  .main-banner-section {
    background-image: url('./assets/images/homepage/website-banner.png');
    background-repeat: no-repeat;
    background-position: center;
  }

  .contact-shadow {
    background-image: url('./assets/images/homepage/contact-us.svg');
  }

  .field-btn {
    letter-spacing: 0.3px;
    font-size: 10px !important;
    font-weight: 400;
    line-height: 2.3;
  }

  .custom-dropdown-style .css-13cymwt-control,
  .custom-dropdown-style .css-t3ipsp-control {
    border: none;
    box-shadow: none;
    height: 33px !important;
  }

  .custom-dropdown-style .css-1fdsijx-ValueContainer {
    padding-left: 15px !important;
    height: 33px;
  }

  .custom-dropdown-style .css-1jqq78o-placeholder {
    color: #3C3C3C !important;
    font-size: 15px !important;
    font-weight: 400 !important;
  }

  .custom-dropdown-style .css-1dimb5e-singleValue {
    color: #3C3C3C !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
  }

  .custom-drop-landing .custom-class__input-container {
    color: black !important;
  }

  .custom-drop-landing .custom-class__control {
    height: 33px;
  }

  /***********************************************
    React Select Override Styles
************************************************/

  /*login/join now */
  .css-yk16xz-control,
  .css-1s2u09g-control,
  .css-1s2u09g-control,
  .css-1s2u09g-control {
    border-color: transparent !important;
    border-radius: 10px !important;
    /* width: 336px !important; */
    height: 31px !important;
    min-height: 0 !important;
    /* font-weight: 100; */
    display: flex !important;
    font-size: 13px;
    cursor: pointer !important;
    background-color: transparent !important;
    flex-wrap: wrap !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
  }

  .edit-company-selector .css-qc6sy-singleValue {
    margin: 0;
  }

  .edit-company-selector .css-qc6sy-singleValue .mt-1 {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .css-b62m3t-container {
    width: 100%;
  }

  .css-2b097c-container .css-319lph-ValueContainer,
  .css-b62m3t-container .css-319lph-ValueContainer {
    border-color: #fff !important;
    border-radius: 10px !important;
    color: #3C3C3C !important;
    font-size: 13px !important;
    width: 100% !important;
    height: 28px !important;
    cursor: pointer !important;
    padding: 0 10px;
  }

  .css-tlfecz-indicatorContainer {
    color: transparent !important;
    padding: 0px !important;
  }

  /* .css-tj5bde-Svg {
  display: none !important;
} */

  .css-1okebmr-indicatorSeparator {
    display: none !important;
  }

  .css-1wa3eu0-placeholder,
  .css-14el2xx-placeholder,
  .css-14el2xx-placeholder,
  .css-14el2xx-placeholder {
    color: transparent !important;
    font-weight: 100 !important;
    font-size: 13px !important;
    top: 40% !important;
    cursor: pointer !important;
  }


  .css-4ljt47-MenuList {
    /* width: 316px !important; */
    /* overflow-x: hidden; */
    padding: 6px !important;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .css-4ljt47-MenuList::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  /*dropdown menu*/
  .css-26l3qy-menu {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%) !important;
    border-radius: 10px !important;
    width: 336px !important;
    font-weight: 100;
    font-size: 13px;
    /* padding: 10px; */
    margin-top: 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer !important;
  }

  .css-1gtu0rj-indicatorContainer,
  .css-tlfecz-indicatorContainer {
    padding: 8px !important;
  }

  .css-tlfecz-indicatorContainer svg,
  .css-1gtu0rj-indicatorContainer svg,
  .css-tlfecz-indicatorContainer:first-child,
  .css-1gtu0rj-indicatorContainer:first-child {
    display: none !important;
  }

  .css-tlfecz-indicatorContainer:last-child:after,
  .css-1gtu0rj-indicatorContainer::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 8px;
    border-top: 6px solid #203C6E;
  }

  .css-26l3qy-menu .css-9gakcf-option {
    /* width: 316px !important; */
  }

  .css-1hwfws3 {
    border-radius: 10px !important;
    width: 336px !important;
    height: 2rem !important;
    cursor: pointer !important;
  }

  /*override input field*/
  .css-hk0a6 {
    border-radius: 10px !important;
    width: 336px !important;
    height: 2rem !important;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer !important;
  }

  .css-vz26jj-singleValue {
    top: 40% !important;
  }

  /*to hide dropdown icon styling*/
  .css-8mmkcg {
    display: none !important;
  }

  /*fixing overlay dropdown issue - city country*/
  .css-1pahdxg-control {
    border-radius: 10px !important;
    font-size: 13px !important;
    width: 100% !important;
    height: 31px !important;
    min-height: 0 !important;
    cursor: pointer !important;
    border: 0px !important;
    box-shadow: none !important;
    border-color: transparent !important;
  }

  /*toastify container styling override*/
  .Toastify__toast {
    border-radius: 10px !important;
    background: #fff !important;
    box-shadow: 0 3px 6px rgb(0 0 0 / 21%) !important;
    font-family: Poppins;
    font-weight: normal;
    font-size: 13px;
    min-width: 297px;
    cursor: pointer !important;
  }

  /*options background in the dropdown menu - city countries*/
  /*selected field*/
  .css-1i102g8-option {
    background-color: #EDF2F5 !important;
    border-radius: 10px !important;
    cursor: pointer !important;
  }

  /*hover field*/
  .css-1jirzoc-option {
    background-color: #edf2f5 !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  /*options background in the dropdown menu - most recent jobs*/
  /*hover field*/
  .css-1n7v3ny-option {
    background-color: #edf2f5 !important;
    border-radius: 10px !important;
    padding: 0 !important;
    /* width: 316px !important; */
    cursor: pointer !important;
  }

  /*selected field*/
  .css-9gakcf-option {
    background-color: #EDF2F5 !important;
    border-radius: 10px !important;
    padding: 0 !important;
    /* width: 316px !important; */
    cursor: pointer !important;
  }

  .css-yt9ioa-option {
    padding: 0 !important;
  }

  /*override svg styling for toastify message box*/
  .Toastify__toast svg {
    margin-bottom: 60px;
  }

  #react-select-3-listbox, #react-select-5-listbox, .custom-class__menu {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%) !important;
    min-height: 260px;
    overflow: hidden;
    z-index: 1000 !important;
    border-radius: 8px;
/* padding: 0px 10px; */
    /* overflow-x: scroll; */
  }

  /*most recentjob title dropdown alignment textfield*/
  .css-7pg0cj-a11yText {
    height: 1;
    cursor: pointer !important;
  }

  /* to style dropdown for most recent company  */
  /* #react-select-3-listbox > .css-11unzgr {
  width: 350px;
} */

  /*hover field*/
  /* #react-select-3-listbox > .css-1n7v3ny-option {
  width: 350px;
} */
  /*selected field*/
  /* #react-select-3-listbox > .css-9gakcf-option {
  width: 350px;
} */

  /***********************************************
    Accordion - Read more read less 
************************************************/
  .read-more-state {
    display: none;
  }

  .read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .read-more-target img {
    height: 0;
  }

  .read-more-state:checked~.read-more-wrap .read-more-target img {
    height: auto;
  }

  .read-more-state:checked~.read-more-wrap .read-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }


  .read-more-state~.read-more-trigger:before {
    content: url('./assets/images/website-event/arrow-down.svg');
    margin-right: 0;
    margin-top: -97px;
    position: absolute;
    right: 220px;
  }

  .read-more-state:checked~.read-more-trigger:before {
    content: url('./assets/images/website-event/arrow-up.svg');
    margin-right: 0;
    margin-top: -97px;
    position: absolute;
    right: 220px;
  }

  .read-more-trigger {
    cursor: pointer;
    display: inline-block;
  }

  .read-more-wrap {
    margin-top: -25px;
  }

  /***********************************************
    Accordion - Read more read less - Half - 2
************************************************/

  .read-more-state2 {
    display: none;
  }

  .read-more-target2 {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .read-more-target2 img {
    height: 0;
  }

  .read-more-state2:checked~.read-more-wrap2 .read-more-target2 img {
    height: auto;
  }

  .read-more-state2:checked~.read-more-wrap2 .read-more-target2 {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }

  .read-more-state2~.read-more-trigger2:before {
    content: url('./assets/images/website-event/arrow-down.svg');
    position: absolute;
    margin-top: -145px;
    left: 42em;
  }

  .read-more-state2:checked~.read-more-trigger2:before {
    content: url('./assets/images/website-event/arrow-up.svg');
    position: absolute;
    margin-top: -145px;
    left: 42em;
  }

  .read-more-trigger2 {
    cursor: pointer;
    display: inline-block;
  }

  .read-more-wrap2 {
    margin-top: -25px;
  }

  /***********************************************
    Accordion - Read more read less - Half - 3
************************************************/
  .read-more-state3 {
    display: none;
  }

  .read-more-target3 {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .read-more-target3 img {
    height: 0;
  }

  .read-more-state3:checked~.read-more-wrap3 .read-more-target3 img {
    height: auto;
  }

  .read-more-state3:checked~.read-more-wrap3 .read-more-target3 {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }

  .read-more-state3~.read-more-trigger3:before {
    content: url('./assets/images/website-event/arrow-down.svg');
    position: absolute;
    margin-top: -145px;
    left: 75em;
  }

  .read-more-state3:checked~.read-more-trigger3:before {
    content: url('./assets/images/website-event/arrow-up.svg');
    position: absolute;
    margin-top: -145px;
    left: 75em;
  }

  .read-more-trigger3 {
    cursor: pointer;
    display: inline-block;
  }

  .read-more-wrap3 {
    margin-top: -25px;
  }


  /***********************************************
    Accordion - Read more read less - Half - 4
************************************************/
  .see-more-state {
    display: none;
  }

  .see-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }

  .see-more-target img {
    height: 0;
  }

  .see-more-state:checked~.see-more-wrap .see-more-target img {
    height: auto;
  }

  .see-more-state:checked~.see-more-wrap .see-more-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }

  .see-more-state~.see-more-trigger:before {
    content: 'Show more';
  }

  .see-more-state:checked~.see-more-trigger:before {
    content: 'Show less';
  }

  .see-more-trigger {
    cursor: pointer;
    display: inline-block;
    border: 1px solid #fff;
  }

  .see-more-state~.img-arrow:before {
    content: url('./assets/images/website-event/arrow-down.svg');
  }

  .see-more-state:checked~.img-arrow:before {
    content: url('./assets/images/website-event/arrow-up.svg');
  }

  .search-page-wrapper {
    width: 809px;
  }

  /******* Event Filter Dropdown Scroll Style  **********/
  .event-dropdown-srcoll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .event-dropdown-srcoll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  /*******  Block section scroll *******/
  .block-section-scroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .block-section-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .signUpScroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .signUpScroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .hide-scroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .hide-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  /*********** Notification Switch Button Custom Style **********/
  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
    width: 33px !important;
    height: 18px !important;
    background-color: #fff !important;
    border-radius: 10px;
    opacity: 1 !important;
  }

  .css-1yjjitx-MuiSwitch-track {
    width: 33px !important;
    height: 18px !important;
    background-color: #CDDCF2 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
  }

  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #203C6E !important;
    top: 3.6px !important;
    left: -1px !important;
  }

  .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
    color: #707070 !important;
    left: 4px !important;
    top: 3.6px !important;
  }

  .css-jsexje-MuiSwitch-thumb {
    width: 16px !important;
    height: 16px !important;
  }

  /*********** Global Checkbox Style **********/

  ul input[type="checkbox"] {
    appearance: none;
    background-color: transparent;
    margin: 0;
    font: inherit;
    color: #707070;
    width: 15px;
    height: 15px;
    border: 1px solid #707070;
    border-radius: 5px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    position: relative;
  }

  ul input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #ffffff;
    background-color: purple;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    position: absolute;
    top: 0;
    left: 2px;
  }

  ul input[type="checkbox"]:checked {
    background-color: #203C6E;
    border: 1px solid #203C6E;
  }

  ul input[type="checkbox"]:checked::before {
    -webkit-transform: rotate(9deg) scale(0.8);
    /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
    -moz-transform: rotate(9deg) scale(0.8);
    /* Fx 3.5-15 */
    -ms-transform: rotate(9deg) scale(0.8);
    /* IE 9 */
    -o-transform: rotate(9deg) scale(0.8);
    /* Op 10.5-12 */
    transform: rotate(9deg) scale(0.8);
    /* Fx 16+, IE 10+ */
  }

  /***** Hide Collapse tab header ****/
  .hide-header-speackers .hide-on-tab-open {
    display: none;
  }

  .hide-body-section .hide-on-tab-close {
    display: none;
  }

  .hide-body-section .close-collapse-with {
    width: calc(100% - 307px);
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  /******* Single line dropdonw style with class (dropdown-style) ********/
  .dropdown-style .css-13cymwt-control,
  .dropdown-style .css-t3ipsp-control {
    height: 31px;
    min-height: 31px;
    display: flex;
    align-items: center;
    border-radius: 5px !important;
    border-color: #dddddd !important;
    flex-flow: nowrap;
  }

  .dropdown-style .css-1fdsijx-ValueContainer {
    height: 31px;
    padding: 0 12px;
  }

  .dropdown-style .custom-class__value-container {
    height: 31px;
  }

  .dropdown-style .css-1dimb5e-singleValue,
  .dropdown-style .css-1jqq78o-placeholder {
    font-size: 13px;
    font-weight: 300;
    color: #3C3C3C;
    margin: 0;
    line-height: 16px;
  }

  .dropdown-style .css-1u9des2-indicatorSeparator {
    display: none;
  }

  .dropdown-style .css-1xc3v61-indicatorContainer,
  .dropdown-style .css-15lsz6c-indicatorContainer {
    padding: 0 3px 0 0;
    background-image: url(../../panelist-react/public/images/arrow-down-icon.png);
    background-repeat: no-repeat;
    background-position: 0px 7px;
  }

  .show-arrow .css-1xc3v61-indicatorContainer {
    padding: 0 3px 0 0;
    background-image: url(../../panelist-react/public/images/arrow-down-icon.png);
    background-repeat: no-repeat;
    background-position: 0px 7px;
    width: 23px;
    height: 20px;
  }

  .dropdown-style .css-1xc3v61-indicatorContainer svg,
  .dropdown-style .css-15lsz6c-indicatorContainer svg {
    visibility: hidden;
  }

  .dropdown-style .css-1nmdiq5-menu {
    border-radius: 5px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  }

  .dropdown-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList,
  .dropdown-style .css-1nmdiq5-menu .css-vpus1l-MenuList,
  .custom-class__menu-list {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    border-radius: 5px;
    padding: 5px;
    max-height: 260px;
  }

  .dropdown-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList::-webkit-scrollbar,
  .dropdown-style .css-1nmdiq5-menu .css-vpus1l-MenuList::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .dropdown-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList div,
  .dropdown-style .css-1nmdiq5-menu .css-vpus1l-MenuList div {
    min-height: 28px;
    border-radius: 5px;
    color: #515357;
    font-size: 15px;
    padding: 0 6px;
    display: flex;
    align-items: center;

  }

  .dropdown-style .css-tr4s17-option {
    background-color: #EDF2F5;
    color: #515357;
    border-radius: 5px;
  }

  .job-title .css-13cymwt-control,
  .job-title .css-t3ipsp-control {
    border: none;
  }

  .job-title .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }

  .hide-arrow .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }

  .company-selector-style .css-1nmdiq5-menu {
    min-width: 318px;
    border-radius: 10px;
  }

  .hide-border .css-13cymwt-control,
  .job-title .css-t3ipsp-control {
    border: none;
  }

  .company-selector-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList {
    padding: 10px 0;
    border-radius: 10px;
  }

  .dropdown-style .custom-class__option {
    min-height: 28px;
    border-radius: 5px !important;
    color: #000 !important;
    font-size: 14px;
    padding: 0 13px !important;
    display: flex;
    align-items: center;
    font-weight: 900;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Helvetica Neue LT W05 45 Light';
  }

  /* .company-selector-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList > div:first-child {
  margin-top: 10px;
} */

  .company-selector-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList>div {
    margin: 0px 10px 6px;
    max-width: 298px;
    border-radius: 10px;
  }

  .company-selector-style .css-1nmdiq5-menu .css-1n6sfyn-MenuList div {
    padding: 0;
  }

  .company-selector-style .hide-text {
    display: none;
  }

  .company-selector-style .css-1dimb5e-singleValue .hide-p {
    padding: 0 !important;
  }

  .dropdown-style .css-1n7v3ny-option,
  .dropdown-style .css-yt9ioa-option,
  .dropdown-style .css-9gakcf-option {
    height: 29px;
    display: flex;
    align-items: center;
    padding-left: 12px !important;
    font-size: 12px;
    line-height: 18px;
    color: #3F4D67;
    font-weight: 400;
    border-radius: 5px !important;
    /* margin: 0 8px; */
  }

  .dropdown-style .css-9gakcf-option,
  .dropdown-style .css-1n7v3ny-option {
    background-color: #EDF2F5 !important;
    font-size: 12px;
    line-height: 18px;
    color: #3F4D67;
    font-weight: 400;
    border-radius: 5px !important;
  }

  .company-selector-style .css-qc6sy-singleValue .selected-field {
    height: 29px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .company-selector-style .selected-field-company {
    padding: 0 !important;
  }

  .company-selector-style .css-1dimb5e-singleValue .selected-label-company {
    font-size: 13px;
    font-weight: 300;
    color: #3C3C3C;
    margin: 0;
    line-height: 16px;
  }

  /****** Sign Up Modal DropDown Style with Class (signup-dropdown) *******/
  .signup-dropdown .css-1s2u09g-control {
    background-color: white !important;
    border-radius: 10px !important;
  }

  .signup-dropdown .css-yk16xz-control,
  .signup-dropdown .css-1s2u09g-control,
  .signup-dropdown .css-1pahdxg-control {
    border-radius: 10px !important;
    height: 30px !important;
  }

  /*******   Privacy Setting DropDown Style   *********/
  .privacy-select-field {
    background-color: white;
  }

  .privacy-select-field .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0;
    border: none;
    height: 40px !important;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 300;
    color: #3C3C3C;
    font-family: 'Poppins';
    text-transform: capitalize;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border-radius: 10px !important;
  }

  .privacy-select-field .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px !important;
    margin-top: 2px;
  }

  ul.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 10px;
  }

  li.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding: 9px 10px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 300;
    color: #3C3C3C;
    font-family: 'Poppins'
  }

  li.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: #EDF2F5;
  }

  li.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    background-color: rgba(25, 118, 210, 0.12);
  }

  /* .MuiSlider-markLabel[data-index="0"]{
    left: 3% !important;
    margin-left: 20px;
  } */

  /*** Event Location map *****/

  .event-location .gm-style-iw {
    width: 395px;
    height: 241px;
    min-height: 241px;
    transform: translate3d(-36%, -26%, 0) !important;
    background-color: rgb(255 255 255 / 97%);
    border-radius: 10px !important;
  }

  .event-location .gm-style-iw .gm-style-iw-d {
    max-height: 241px !important;
  }

  .event-location .gmnoprint,
  .event-location .gm-fullscreen-control,
  .event-location .gm-svpc,
  .event-location .gm-style-cc,
  .event-location .gm-style-iw-tc,
  .event-location .gm-ui-hover-effect {
    display: none !important;
  }

  /********* location-dropdown ************/
  .location-dropdown {
    background-color: transparent;
  }

  .pac-container {
    background-color: white;
    padding: 10px;
    border: none;
    border-radius: 10px;
  }

  .pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0px;
  }

  .pac-item {
    border: none;
    min-height: 35px;
    max-height: 35px;
    border-radius: 5px;
    font-family: 'Poppins';
    width: 100%;
    display: flex;
    align-items: center;
    color: #3c3c3c;
    font-size: 15px;

  }

  .pac-item:hover {
    background-color: #EDF2F5;
  }

  .pac-matched {
    font-family: 'Poppins';
    color: #3c3c3c;
    font-size: 15px;
  }

  /**************** Phone Field Style ******************/
  .phone-field-style {
    display: flex;
    flex-direction: row-reverse;
    justify-content: left;
    gap: 5px;
  }

  .phone-field-style .form-control {
    width: 322px !important;
    height: 31px !important;
    border-radius: 10px !important;
    border: 1px solid #CDDCF2 !important;
    padding-left: 12px !important;
  }

.phone-field-spectre .form-control {
  width: 100% !important;
    height: 43px !important;
    border: none !important;
    background: transparent !important;
}

.phone-field-spectre .form-control::placeholder {
  color: white;
}

.phone-field-spectre .country-name {
  color: black;
}

.phone-field-style .flag-dropdown  {
  width: 73px !important;
    height: 31px;
    background-color: white !important;
    position: relative !important;
    border-radius: 10px !important;
    border: 1px solid #CDDCF2 !important;
  }

.phone-field-spectre .flag-dropdown  {
  width: 73px !important;
    height: 40px !important;
    background-color: transparent !important;
    position: relative !important;
    border-radius: 0px !important;
    border: none !important;
    border-right: 1px solid #ffffff26 !important;
}

.phone-field-style .flag-dropdown .selected-flag {
  width: 73px !important;
    height: 43px;
    background-color: transparent !important;
    padding: 0 0 0 15px;
  }

  .phone-field-style .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -3px !important;
    left: 30px !important;
    width: 0;
    height: 0;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 7px solid #203C6E !important;
    border-radius: 3px !important;
  }

.phone-field-spectre .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -3px !important;
  left: 30px !important;
  width: 0;
  height: 0;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid #203C6E !important;
  border-radius: 3px !important;
  background-image: url('./assets/images/arrow-select.svg');
  width: 17px !important;
    height: 20px !important;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    border: none !important;
    background-size: 14px;
}

.phone-field-style .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 0px solid #555 !important;
}

  .arrow-remove .css-1hb7zxy-IndicatorsContainer>.css-1xc3v61-indicatorContainer:first-child {
    display: none;
  }

  .arrow-remove .css-1hb7zxy-IndicatorsContainer>.css-15lsz6c-indicatorContainer:first-child {
    display: none;
  }

  .corp-area-size .reactEasyCrop_CropArea {
    width: 532px !important;
    height: 126px !important;
  }

  .tags-style .css-1p3m7a8-multiValue {
    background-color: #203C6E;
    color: white !important;
    height: 23px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 20px;

  }

  .tags-style .css-9jq23d {
    color: white !important;
  }

  .tags-style .css-wsp0cs-MultiValueGeneric {
    padding: 0;
    color: white;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 13px;
  }

  .tags-style .css-v7duua {
    background-color: transparent;
    background-image: url('./assets/images/white-cross.png');
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    padding: 0;
    margin-left: 20px;
  }

  .tags-style .css-12a83d4-MultiValueRemove {
    background-color: transparent;
    background-image: url('./assets/images/white-cross.png');
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    padding: 0;
    margin-left: 20px;
  }

  .tags-style .css-12a83d4-MultiValueRemove svg {
    display: none;
  }

  .tags-style .css-12a83d4-MultiValueRemove:hover {
    background-color: transparent;
    color: white;
  }

  .tags-style .css-1hb7zxy-IndicatorsContainer .css-1xc3v61-indicatorContainer {
    display: none;
  }

  .scroll-style::-webkit-scrollbar {
    width: 6px;
    /* width of the entire scrollbar */
  }

  .scroll-style::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

.text-number-filed::-webkit-inner-spin-button, 
.text-number-filed::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/**** Spectre Agenda section scrollbar style *****/
.agenda-scroll-style {
 padding-bottom: 10px;
}

.agenda-scroll-style::-webkit-scrollbar {
  width: 6px;     
  height: 11px;          /* width of the entire scrollbar */
}

.agenda-scroll-style::-webkit-scrollbar-track {
  background: black;
  height: 11px;     
  border-radius: 10px;   /* color of the tracking area */
}

.agenda-scroll-style::-webkit-scrollbar-thumb {
  background-color: white;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
  border: 0px;  /* creates padding around scroll thumb */
}
  .scroll-style::-webkit-scrollbar-thumb {
    background-color: #203C6E;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
    border: 0px;
    /* creates padding around scroll thumb */
  }

  .text-number-filed::-webkit-inner-spin-button,
  .text-number-filed::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .website-cover-crop .reactEasyCrop_Container {
    border-radius: 0px !important;
  }
    
  .organization-scroll {
    height: 58px;
    overflow-y: scroll;
  }

  .spectre-input-new .form-control , .spectre-input-new-bottom .form-control{
    width: 100% !important;
    height: 43px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    padding-left: 85px !important;
  }

  .spectre-input-new .selected-flag .arrow , .spectre-input-new-bottom .selected-flag .arrow  {
    border-left: 7px solid transparent !important;
    border-right: 7px solid transparent !important;
    border-top: 7px solid #555 !important;
  }

  .spectre-input-new .selected-flag .arrow.up ,  .spectre-input-new-bottom .selected-flag .arrow.up{
    border-top: none !important;
    border-bottom: 7px solid #555 !important;
}
.spectre-input-new  .selected-flag .flag , .spectre-input-new-bottom  .selected-flag .flag   {
  transform: scale(1.1);
}

  .spectre-input-new .flag-dropdown , .spectre-input-new-bottom .flag-dropdown  {
  
    background-color: transparent !important;
    min-width: 70px;
    height: 41px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    border-right: 1px solid #ffffff26!important;
  }
  .spectre-input-new .flag-dropdown .country-list   {
    left: 0;
    top: 30px;
    border-radius: 4px;
  }

  .spectre-input-new-bottom .flag-dropdown .country-list   {
    left: 0;
    bottom: 35px;
    border-radius: 4px;
  }

  .spectre-input-new .flag-dropdown .country-list .search-box, .spectre-input-new .flag-dropdown .country-list .country-name ,.spectre-input-new-bottom .flag-dropdown .country-list .search-box , .spectre-input-new-bottom .flag-dropdown .country-list .country-name{
    color: #000;
  }
  .spectre-input-new .flag-dropdown.open , .spectre-input-new-bottom .flag-dropdown.open  {
    background-color: transparent !important;
  }
  /* .spectre-input-new .form-control:hover {
    border-color: #838383!important;
  } */
  .spectre-input-new .selected-flag:hover, .spectre-input-new .selected-flag:focus, .spectre-input-new .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }

  .spectre-input-new-bottom .selected-flag:hover, .spectre-input-new-bottom .selected-flag:focus, .spectre-input-new-bottom .flag-dropdown.open .selected-flag {
    background-color: transparent !important;
  }

  /* .spectre-input-new .form-control:focus, .spectre-input-new .form-control:focus-visible {
    box-shadow: 0 0 0 0.15em rgba(0, 255, 205, 1) !important;
  }  */

/***********************  *******************************/
  @media only screen and (max-width: 600px) and (min-width: 300px)  {
    .h-line {
      display: none;
    }
    .width-auto {
      width: auto;
    }
    .custom-gap-x {
      column-gap: 20px;
    }
    .mobile-p-20 {
      padding: 0 10px 10px;
    }
    .phone-field-style .form-control {
      width: 100% !important;
    }
    .mobile-modal-position {
      top: 72px;
      width: 95vw !important;
      max-height: calc(100vh - 100px);
      height: 100%;
      height: calc(100% - 200px);
    }
    .mobile-gap-10 {
      gap: 10px;
    }
    .fields-mobile-height {
      max-height: calc(100vh - 325px);

    }
    .mobile-nav-btn {
      width: 106px;
    }
    .mobile-nav-banner-btn {
      width: 200px;
    }
    .m-h-login {
      height: 360px;
    }
    .mobile-mt-login {
      margin-top: 87px;
    }
    .mobile-grid-center {
      display: grid;
      width: auto;
      align-items: center;
      justify-content: center;
    }
    .mobile-mt-10 {
      margin-top: 10px;
    }
    .mobile-mt-20 {
      margin-top: 20px;
    }
    .mobile-pt-15 {
      padding-top: 15px;
    }
    .mobile-input-width {
      width: 100% !important;
    }
    .mobile-signup-form {
      width: 100%;
      max-height: calc(100vh - 300px);
    }
    .mobile-w-full {
      width: 100%;
    }
    .mobile-h-full {
      height: 100%;
    }
    .mobile-min-width {
      min-width: 100%;
    }
    .padding-y-0 {
      padding: 0;
    }
    .profile-main-div {
      flex-direction: column;
      padding: 0 10px;
      overflow-y: scroll;
      max-height: 342px;
      justify-content: flex-start;
    }
    .mobile-distance-btn {
      width: 100%;
    }
    .mobile-p-x {
      padding: 0 15px;
    }
    .margin-r-15 {
      margin-right: 15px;
    }
    .login-form-height {
      height: 255px !important;
    }
    .complete-profile-form {
      margin: 0;
      padding: 0;
      border: none;
    }
    .mobile-tick {
      max-width: 50px;
    }
    .mobile-qr {
      max-width: 150px;
    }
    .profile-mobile-crop {
      width: 100% !important;
    }
  }

 .class-name-custom.specialities-create__menu {
    display: none;
  }

  .specialities-create__menu {
    padding:0px 5px
  }

  .specialities-create__menu .specialities-create__option--is-focused {
    background-color: #edf2f5;
    border-radius: 5px;
  }

  .specialities-create__menu .specialities-create__option {
    border-radius: 5px;
    font-size: 15px;
    color: #000000;
    padding: 4px 12px;
    margin-bottom: 4px;
    cursor: pointer;
  }


  /* width */
  .show-scroll-bar::-webkit-scrollbar {
    display: block !important;
    width: 3px;
    
  }

  /* Track */
  .show-scroll-bar::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  .show-scroll-bar::-webkit-scrollbar-thumb {
    background: #e9eff2;
    border-radius: 20px;
  }

  /****************** Media Query ********************/
  @media screen and (min-device-width: 320px) and (max-device-width: 1080px) { 

    .landing-nav-shadow {
      padding: 0 20px;
    }

    .footer-mobile {
      row-gap: 5px;
      margin-top: 20px;
    }

    .m-h-80 {
      height: 80vh !important;
    }

  }

  @media screen and (min-device-width: 300px) and (max-device-width: 768px) { 

    .m-text-center {
      text-align: center;
    }
  }

  @media screen and (min-device-width: 768px) { 
   
    .b-nav-shadow {
      box-shadow: none;
    }

  }
/*********************** brading scroll  *******************************/
  .branding-scroll {
    max-height: 100%;
    overflow-y: scroll;
  }
  .branding-scroll::-webkit-scrollbar {
    width: 5px;
    display: block !important;
  }
  
  .branding-scroll::-webkit-scrollbar-track {
    background-color: #FFFFFF;
    border-radius: 20px;
    width: 5px;
  }
  
  .branding-scroll::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color, #0131FF);
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
    min-height: 46px;
    max-height: 46px;
  }
  
  .branding-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color, #0131FF);
  }
/*********************** brading scroll  *******************************/

  .custom-scroll {
    max-height: 100%;
    overflow-y: scroll;
  }
  .custom-scroll::-webkit-scrollbar {
    width: 9px;
    display: block !important;
  }
  
  .custom-scroll::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
    width: 9px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color, #00FF62);
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
    min-height: 46px;
    max-height: 46px;
  }
  
  .custom-scroll::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color, #00FF62);
  }


  .custom-scroll-default::-webkit-scrollbar {
    width: 6px;
    display: block !important;
  }
  
  .custom-scroll-default::-webkit-scrollbar-track {
    /* background-color: rgba(204, 204, 204, 0.52); */
    border-radius: 20px;
    width: 6px;
  }
  
  .custom-scroll-default::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color, #4d144a);
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
    min-height: 46px;
    max-height: 46px;
  }
  
  .custom-scroll-default::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color, #4d144a);
  }


  .custom-scroll-spectre {
    max-height: 100%;
    overflow-y: scroll;
  }
  .custom-scroll-spectre::-webkit-scrollbar {
    width: 15px;
    display: block !important;
  }
  
  .custom-scroll-spectre::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .custom-scroll-spectre::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color, rgba(0, 255, 205, 1));
    border-radius: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
    min-height: 46px;
    max-height: 46px;
  }
  
  .custom-scroll-spectre::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrollbar-color, rgba(0, 255, 205, 1));
  }

  @media screen and (max-width: 1100px) {
    .agenda-card-spectre-new {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 14px;
      grid-row-gap: 14px;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .feed-media-max-w {
      max-width: 100%;
    }

    .agenda-card-spectre-new {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 14px;
      grid-row-gap: 14px;
      width: 100%;
    }
  }

  .custom-scroll-container {
    width: 300px;
    height: 200px;
    overflow-y: scroll;
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 transparent; /* For Firefox */
  }
  
  .custom-scroll-container::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .custom-scroll-container::-webkit-scrollbar-track {
    background: "white"; /* Color of the track */
  }
  
  .custom-scroll-container::-webkit-scrollbar-thumb {
    background-color: #707070; /* Color of the thumb */
    border-radius: 4px; /* Radius of the thumb */
  }

  /***********************/
  .custom-video-popup-scroll {
    max-height: 100%;
    overflow-y: auto;
  }
  .custom-video-popup-scroll::-webkit-scrollbar {
    width: 7px;
    display: block !important;
  }
  
  .custom-video-popup-scroll::-webkit-scrollbar-track {
    background-color: #707070;
    border-radius: 20px;
    width: 7px;
  }
  
  .custom-video-popup-scroll::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
    min-height: 10px;
    max-height: 10px;
  }
  
  .custom-video-popup-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
  }

  /**********Dropdowns multi select*************/
  .specialities-create__control {
    min-height: 91px !important;
    border-radius: 5px !important;
    gap: 10px !important;
    padding: 0px !important;
    align-items: flex-start !important;
    width: calc(100% - 0px) !important;
    border: 1px solid #d3d3d3 !important;
  }

  .specialities-create__value-container {
    gap: 5px;
    padding: 11px 11px !important;
  }

/* Track */
.slider-timeline {
  -webkit-appearance: none;
  width: 100%;
  height: 8.5px;
  background-color: #96aecd; /* Change track color */
  border-radius: 12px;
}

/* Thumb */
.slider-timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background-color: #0031ff; /* Change thumb color */
  border-radius: 50%;
  cursor: pointer;
}

/* Thumb hover effect */
.slider-timeline::-webkit-slider-thumb:hover {
  background-color: #0056b3;
}

/* Thumb active state */
.slider-timeline::-webkit-slider-thumb:active {
  background-color: #0056b3;
}

.footer-socials svg{
  min-width: 34px;
  min-height: 34px;
  max-width: 34px;
  max-height: 34px;
  background-color: #2c2c2c;
  border-radius: 5px;
  outline: 1px solid #2c2c2c;
}

.card-no {
  position: relative;
}

.cards .icon {
  font-size: 36px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.card-no > input + * {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.cardType {
  text-transform: uppercase;
  font-weight: 700;
}
/*  */
.canvas {
  min-height: 100vh;
  max-width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
}

.donation-form {
  display: grid;
  row-gap: 20px;
  padding: 20px;
}

.section-inline__start {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-inline__end .section-title {
  text-transform: uppercase;
}

.section-inline__end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-inline__end .donation-amount {
  font-size: 60px;
}

.form-field__title {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}

.card-no .form-field {
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0; */
}

.date-time .form-field {
  /* border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-right: 0; */
}

.cvv .form-field {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.form-field {
  padding: 0px 12px;
  height: 43px;
  width: 100%;
  border-radius: 8px;
  font-family: Poppins-400;
  font-size: 0.875rem;
  /* background: black; */
  /* background-color: var(--field-background-color, #000000); */
  /* border: 1px solid #d0d0d0; */
  /* border-radius: 7px; */
}

.form-field::placeholder {
  color: white;
}

.card-options {
  display: flex;
}

.pay-button {
  width: 100%;
  padding: 5px 16px;
  height: 48px;
  border: none;
  background: black;
  color: white;
  font-size: 20px;
  border-radius: 7px;
}

.form-field__title + .donation-amount__wrap {
  position: relative;
}

.currencySymbol {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-weight: 500;
}

.donation-amount__wrap .form-field {
  padding-left: 32px;
}

ul {
  list-style: disc;
  /* padding-left: 15px !important; */
}

li::marker {
  color: black !important;
}

.richTextStyle ul {
  list-style: disc;
  padding-left: 15px;
}

.richTextStyle li::marker {
  color: black !important;
}

.richTextStyle a {
  color: #0049ef !important;
}

.list-style-bullets ul {
  list-style: disc;
  padding-left: 15px;
}

.list-style-bullets li::marker {
  color: white !important; /* Change the color of the list item markers */
}

.list-style-bullets-website ul {
  list-style: disc;
  padding-left: 15px;
}

.list-style-bullets-website li::marker {
  color: black !important; /* Change the color of the list item markers */
}

.pdf-image-viewer {
  height: 100%;
}

.pdf-image-viewer .slick-dots {
  bottom: 0px;
  background-color: rgba(0,0,0,0.75);
  display: flex !important;
  align-items: center;
  height: 28px;
  justify-content: center;
  overflow: auto;
}

.pdf-image-viewer .slick-dots li {
  margin: 0px;
  width: 25px;
  height: 4px;
}

.pdf-image-viewer .slick-dots li button {
  padding: 0px;
  width: 25px;
  height: 4px;
  overflow: hidden;
  background-color: rgba(255 255 255 / 50%);
}
.pdf-image-viewer .slick-dots li.slick-active button {
  background-color: rgba(255 255 255 / 100%);
}

.pdf-image-viewer .slick-dots li button::before {
  display: none;
}

.pdf-image-full img {
  max-height: 100vh;
  height: 100vh;
}

.pdf-image-full .slick-track {
  height: 100vh;
}

.pdf-image-main-post img {
  max-height: 100%;
  height: 100%;
  border-radius: 0;
  margin-bottom: -6px;
}

.pdf-image-main-post .slick-track {
  height: 100%;
  min-height: 100%;
}

.pdf-image-main-post .slick-list {
  height: 100% !important;
  min-height: 100%;
}

.pdf-image-main-post .slick-track .slick-slide div {
  height: 100%;
  min-height: 100%;
}


.scroll-container {
  width: 300px;
  height: 200px;
  overflow-y: scroll; /* Always show the scrollbar */
  background-color: #222;
  padding: 10px;
}

.scroll-container::-webkit-scrollbar {
  width: 10px; /* Custom scrollbar width for WebKit browsers like Chrome and Safari */
  display: block;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-image: url('https://cdn.pixabay.com/photo/2024/12/13/10/23/woman-9264738_960_720.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 30pxpx;
  width: 10px;
  width: 10px;
  position: relative;
}

.scroll-container::-webkit-scrollbar-thumb::before {
  content: ''; /* Required to create the pseudo-element */
  position: absolute;
  top: -5px; /* Adjusts the position of the circle */
  left: 50%; /* Centers the circle horizontally */
  transform: translateX(-50%); /* Centers the circle horizontally */
  width: 10px; /* Circle width */
  height: 10px; /* Circle height */
  background-color: white; /* White background */
  border-radius: 50%; /* Makes it a circle */
}


.scroll-container::-webkit-scrollbar-track {
  background-color: #333;
}

.branding-drawer {
  right: 70px;
}

@keyframes floatUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-500px);
    opacity: 0;
  }
}

.animate-float-up {
  animation: floatUp 4s ease-out forwards;
  position: absolute; /* Ensure proper positioning */
  bottom: 0; /* Initial position */
}

.blue-cirlce {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-270deg);
  transform-origin: 50% 50%;
}