.call-animation {
    background: #fff;
    width: 70px;
    height: 70px;
    position: relative;
    margin: 4px 4px auto;
    border-radius: 30px;
    border: solid 2px #fff;
    animation: play 2s ease infinite;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;

}

@keyframes play {

    0% {
        transform: scale(1);
    }

    15% {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.4);
    }

    25% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.4), 0 0 0 20px rgba(255, 255, 255, 0.2);
    }

    25% {
        box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.4), 0 0 0 30px rgba(255, 255, 255, 0.2);
    }

}