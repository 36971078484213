@import url(./font.css);
@import "swiper/css";


.helvetica-neue-W05_25-ult {
    font-family:'Helvetica Neue LT W05_25 Ult L';
}

.helvetica-neue-45-light {
    font-family: 'Helvetica Neue LT W05 45 Light';
    letter-spacing: 0.1px;
    font-weight: 300;
}

.helvetica-neue-55-roman {
    font-family:'Helvetica Neue LT W05 55 Roman' !important;
    letter-spacing: 0.1px;
}

.helvetica-neue-55-roman-wide {
    font-family:'Helvetica Neue LT W05 55 Roman';
    letter-spacing: 0.9px;
}

.helvetica-neue-45-light-wide {
    font-family: 'Helvetica Neue LT W05 45 Light';
    letter-spacing: 1px;
}

.helvetica-neue-65-medium {
    font-family:'Helvetica Neue LT W05 65 Medium' !important;
    line-height: '100%';
}

.helvetica-neue-35-thin-wide {
    font-family:'Helvetica Neue LT W05 35 Thin';
    letter-spacing: 0.2px;
}

.helvetica-neue-35-thin {
    font-family:'Helvetica Neue LT W05 35 Thin';
}

.helvetica-neue-W05_57-thin {
    font-family:'Helvetica Neue LT W05_57 Cond' !important;
}

.helvetica-neue-W05_85-heavy {
  font-family:'Helvetica Neue LT W05 85 Heavy' !important;
}

.helvetica-neue-W05_75-bd {
  font-family:'Helvetica Neue LT W05_75 Bd Ou' !important;
}

html {
  scroll-behavior: smooth;
}

.contact-form-select .custom-class__placeholder {
    color: #ffffff !important;
    font-size: 13px !important;
    line-height: 15px !important;
    font-weight: 500 !important;
    font-family: 'Helvetica Neue LT W05 45 Light';
    padding: 4px 0 0;
  }

  .contact-form-select .custom-class__single-value {
    color: #ffffff !important;
    font-size: 13px !important;
    line-height: 15px !important;
    font-weight: 500 !important;
    font-family: 'Helvetica Neue LT W05 45 Light';
    padding: 4px 0 0;
  }

  .custom-class__input-container {
    color: #ffffff !important;
  }

  .contact-form-select .custom-class__indicators {
    background-image: url('../../../assets/images/arrow-select.svg');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    width: 30px;
    height: 33px;
  }

  .contact-form-select .custom-class__indicators .custom-class__indicator {
    display: none;
  }

  /*Reg custom fields*/

  .reg-custom-field__placeholder {
    color: #ffffff !important;
    font-size: 13px !important;
    line-height: 15px !important;
    font-weight: 500 !important;
    font-family: 'Helvetica Neue LT W05 45 Light';
    padding: 4px 0 0;
  }

  .reg-custom-field__control {
    background-color: transparent !important;
    height: 35px !important;
    border: 1px solid #8f8f8f !important;
  }

  .reg-custom-field__input-container, .reg-custom-field__single-value {
    color: #ffffff !important;
  }

  .reg-custom-field__indicators {
    background-image: url('../../../assets/images/arrow-select.svg');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    width: 30px;
    height: 33px;
  }

  .reg-custom-field__indicators .reg-custom-field__indicator {
    display: none;
  }

  .landingSwiper {
    width: 460px;
    min-height: 463px;
  }

  .landingSwiper .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    margin: 3px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 4px;
  }

  .landingSwiper .swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    background-color: white !important;
    border-radius: 6px;
  }

  .landingSwiper .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 4px;
    transform: scale(1);
    opacity: 1;
  }

  /** Factor swiper slider for insight **/
  .inspirationSlider {
    padding-left: 55px;    padding-right: 55px;

  }

  .inspirationSlider .swiper-pagination {
    bottom: 30px;
  }

  .partnerVidoe, .partnerVidoe .react-player__preview {
    min-height: 255px;
  }

  .partnerVidoe video {
    border-radius: 10px;
    object-fit: cover;
  }
  .experience-single-video video {
    object-fit: cover;
  }
  .overview-single-video video {
    object-fit: cover;
    border-radius: 10px;
  }
  .experienceVideo {
    height: 750px;
    border-radius: 0px;
  }

  .experienceVideo video {
    border-radius: 0px !important;
  }

  .overviewVideo {
    height: 475px;
    border-radius: 10px;
  }
  .factorSwiper {
    /* height: 1091px;
    min-height: 1091px; */
    position: relative;
    z-index: 0;
  }

  .factorSwiper video {
    object-fit: cover;
    border-radius: 10px;
  }

  .factorSwiper .swiper-slide {
    padding-bottom: 35px;
  }

  .experienceVideo .swiper-slide {
    padding-bottom: 35px;
  }


  /* .factorSwiper .swiper-slide img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  } */

  .factorSwiper .swiper-pagination {
    /* padding: 0 0 15px; */
    z-index: 10000;
  }

  .factorSwiper .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
    margin: 3px;
    background-color: #14161A;
    border-radius: 10px;
  }

  .factorSwiper .swiper-pagination-bullet-active {
    width: 28px;
    height: 5px;
    background-color: white !important;
    border-radius: 10px;
    pointer-events: none !important;
  }
  
  .factorSwiper .swiper-pagination-clickable .swiper-pagination-bullet {
    background-color: #14161A;
    opacity: 1;
  }

  .factorSwiper .swiper-pagination-bullet-active-next, .factorSwiper .swiper-pagination-bullet-active-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .factorSwiper .swiper-pagination-bullet-active-next-next, .factorSwiper .swiper-pagination-bullet-active-prev-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }
  /****/

  .mySwiper {
    height: 920px;
    min-height: 920px;
    position: relative;
    z-index: 0;
  }

  .mySwiper .swiper-slide img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .mySwiper .swiper-pagination {
    padding: 0 0 15px;
    z-index: 10000;
  }

  .mySwiper .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
    margin: 3px;
    background-color: white;
    border-radius: 10px;
  }

  .mySwiper .swiper-pagination-bullet-active {
    width: 16px;
    height: 4px;
    background-color: white;
    border-radius: 10px;
  }
  
  .mySwiper .swiper-pagination-clickable .swiper-pagination-bullet {
    background-color: white;
    opacity: .9;
  }

  .mySwiper .swiper-pagination-bullet-active-next, .mySwiper .swiper-pagination-bullet-active-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiper .swiper-pagination-bullet-active-next-next, .mySwiper .swiper-pagination-bullet-active-prev-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiperBox .swiper-pagination-clickable .swiper-pagination-bullet {
    background-color: white;
    opacity: .9;
  }

  .mySwiperBox .swiper-pagination-bullet-active-next, .mySwiperBox .swiper-pagination-bullet-active-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiperBox .swiper-pagination-bullet-active-next-next, .mySwiperBox .swiper-pagination-bullet-active-prev-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiperBox .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #013370 !important;
  }


  .reg-bg {
    background-image: url('https://assets-global.website-files.com/61b0923aba8748eca71f51c2/625165f1dec7874f774160da_shutterstock_1715655535%20(1).jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .swiper-scrollbar {
    display: none;
  }

  .spectreSpeakerSlider {
    height: 303px;
  }

  .spectreSpeakerSlider  .swiper-pagination-bullet {
    width: 4px;
    height: 4px;
  }

  .spectreSpeakerSlider .swiper-pagination{
    bottom: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spectreSpeakerSlider .swiper-pagination-clickable .swiper-pagination-bullet {
    background-color: #676767;
    opacity: .9;
  }

  .spectreSpeakerSlider .swiper-pagination-bullet-active {
    width: 16px;
    height: 4px;
    background-color: #ffffff !important;
    border-radius: 10px;
  }

  .moving-image{
    animation: moving 30s linear infinite;
  }

  @keyframes moving {
    0%{
      transform: translateY(-5%);
    }
    100%{
      transform: translateY(-100%);
    }
  }
  
  @media screen and (max-width: 640px) {
    .landingSwiper {
      width: 320px;
      min-height: 375px;
    }
  }

  /* @media screen and (min-width: 1024px) {
    .factorSwiper {
      height: 685px;
      min-height: 685px;
    }
  
  } */