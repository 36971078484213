.event-card-single {
    box-shadow: rgba(0 0 0 / 30%) 0px 0px 1px;
    border-radius: 10px;
    width: 218px;
}

.event-card-single .event-card-single-cover {
    height: 102px;
}

.event-card-single .event-card-single-text {
    min-height: 92px;
}

.event- card-single .event-date-time {
    color: #203C6E;
}

@media screen and (min-device-width: 300px) and (max-device-width: 768px) { 
    .event-card-single {
        width: 100%;
    }
}

@media screen and (max-device-width: 1280px) { 
    .event-card-single {
        width: 100%;
    }
}

