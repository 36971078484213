.circular-loader {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 100%;
}

.circle-bg {
  fill: none;
  stroke: white;
  stroke-width: 6;
}

.circle {
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  stroke: #0031ff;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-180deg);
  transform-origin: 50% 50%;
}

.inner-circle {
  fill: white;
  stroke: #031fff;
  stroke-width: 0.1;
}

.percentage-small {
  fill: #0031ff;
  font-family: sans-serif;
  font-size: 2px; /* Adjust this value if needed */
  text-anchor: middle;
  dominant-baseline: middle;
}

.small-circle-group {
  transform-origin: 50% 50%;
  transition: transform 0.35s;
}
