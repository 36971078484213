
@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=6e0a16a5-2249-4a1f-a497-6ca10069e6d2&fontids=5309053,5333845,5335212,5350353,5364200,5365713,5365742,5663700,5663704,5663709,5663722,5663727,5664067,5664081,5664085,5664089,5664093,5664098,5664103,5664107,5664111,5664115,5664119,5664128,5664150");
@font-face{
    font-family:"Helvetica Neue LT W05_37 Th Cn";
    src:url("Fonts/5309053/8050b954-b4fb-4d8a-a8c8-b1baa93ff127.woff2") format("woff2"),url("Fonts/5309053/a4b7aeb9-bdc9-4d27-8f9a-6b7384e92533.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_75 Bd Ou";
    src:url("Fonts/5333845/d68aa150-ab14-4dc6-973e-68d88bdf3660.woff2") format("woff2"),url("Fonts/5333845/06e01e5c-196d-42c0-b7a6-ea57b0fbb3e8.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_57 Cn Ob";
    src:url("Fonts/5335212/eb585aea-bb61-4ba1-bb51-8e89dfa63242.woff2") format("woff2"),url("Fonts/5335212/adc6aa68-bc12-4c1f-932f-2b6fb09f15aa.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_95350353";
    src:url("Fonts/5350353/93ce67e5-64e3-4a99-99a3-545f20c5beec.woff2") format("woff2"),url("Fonts/5350353/96b3a113-3e18-46f9-b6e3-5bdee7288b20.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_57 Cond";
    src:url("Fonts/5364200/e16aabe7-128c-4f85-8cf6-b2305472c583.woff2") format("woff2"),url("Fonts/5364200/82bab1eb-0c28-438c-8fc8-0fbe2ccd4835.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_26UltLtI";
    src:url("Fonts/5365713/55c3407d-2666-44fd-b212-634b054732e3.woff2") format("woff2"),url("Fonts/5365713/30b41336-bfb6-41a0-8e73-335d66b0ec31.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_25 Ult L";
    src:url("Fonts/5365742/02b3f97a-406d-43cb-885c-ba5233577cad.woff2") format("woff2"),url("Fonts/5365742/cad33bbd-bbf2-423b-98c5-51136c054e14.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_37ThCnOb";
    src:url("Fonts/5663700/cec55e42-b740-41ea-bb12-f45324e59638.woff2") format("woff2"),url("Fonts/5663700/5e3b3ff0-2fac-45a2-9e6c-b8c2e8d6705a.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_47 Lt Cn";
    src:url("Fonts/5663704/083e1179-98b4-4cbd-a13a-50a7c78d6fea.woff2") format("woff2"),url("Fonts/5663704/239170e6-7f0d-4c35-97d4-490b362e4ef7.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_47LtCnOb";
    src:url("Fonts/5663709/4e0d849a-b425-400e-a40c-789c4a37aac0.woff2") format("woff2"),url("Fonts/5663709/71ce5b5d-325e-417b-843b-e8f6e554a182.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_27UltLtC";
    src:url("Fonts/5663722/fb306239-7f59-4f44-b617-23c761ba3cb1.woff2") format("woff2"),url("Fonts/5663722/a3af510f-3434-46ba-873d-bc503a1a8b4e.woff") format("woff");
}
@font-face{
    font-family:"Helvetica NeueLTW0527UltLtCnOb";
    src:url("Fonts/5663727/cd911115-5657-4542-86e9-b5aa4cbcced7.woff2") format("woff2"),url("Fonts/5663727/2316696f-3b84-4d49-b666-bf2d97f338e6.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05_36 Th It";
    src:url("Fonts/5664067/2a7e8f89-c0b2-4334-9c34-7a2078d2b959.woff2") format("woff2"),url("Fonts/5664067/32aad9d8-5fec-4b9d-ad53-4cf7a5b53698.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 35 Thin";
    src:url("Fonts/5664081/7d63ccf8-e0ae-4dee-ad4d-bbc798aa5803.woff2") format("woff2"),url("Fonts/5664081/b2c1327f-ab3d-4230-93d7-eee8596e1498.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 45 Light";
    src:url("Fonts/5664085/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2") format("woff2"),url("Fonts/5664085/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 46 Lt It";
    src:url("Fonts/5664089/5e4f385b-17ff-4d27-a63a-9ee28546c9a8.woff2") format("woff2"),url("Fonts/5664089/116cde47-4a07-44a5-9fac-cbdcc1f14f79.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 55 Roman";
    src:url("Fonts/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),url("Fonts/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 56 Italic";
    src:url("Fonts/5664098/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"),url("Fonts/5664098/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 65 Medium";
    src:url("Fonts/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),url("Fonts/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 66 Md It";
    src:url("Fonts/5664107/de68be2a-5d0e-4b8d-b3eb-940f75503e2a.woff2") format("woff2"),url("Fonts/5664107/31029e78-79a0-4940-b82d-2e3c238e1355.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 76 Bd It";
    src:url("Fonts/5664111/13ab58b4-b5ba-4c95-afde-ab2608fbbbd9.woff2") format("woff2"),url("Fonts/5664111/5018b5b5-c821-4653-bc74-d0b11d735f1a.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 85 Heavy";
    src:url("Fonts/5664115/7e42a406-9133-48c0-a705-4264ac520b43.woff2") format("woff2"),url("Fonts/5664115/837750e9-3227-455d-a04e-dc76764aefcf.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 86 Hv It";
    src:url("Fonts/5664119/0acba88f-0de4-4d43-81fd-920d7427f665.woff2") format("woff2"),url("Fonts/5664119/713c9c40-9cbd-4276-819e-d0efaf5d3923.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 96 Blk It";
    src:url("Fonts/5664128/995add04-59cc-41ea-abd2-4712eaddf2a8.woff2") format("woff2"),url("Fonts/5664128/7090e465-f6bf-4664-8b5a-d877a6915d87.woff") format("woff");
}
@font-face{
    font-family:"Helvetica Neue LT W05 75 Bold";
    src:url("Fonts/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),url("Fonts/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff");
}
