.survey-questions {
    overflow-Y: auto ;
    align-Items: center;
    max-Height: 650px;
    padding-top: 15px;
    padding-bottom: 15px
}

@media screen and (max-width: 768px) {
    /* Styles for screens with a maximum width of 600px */

   .survey-questions {
    overflow-Y: auto ;
    align-Items: center;
    max-Height: 100%;
    padding-top: 15px;
    padding-bottom: 15px
}
}

.survey-questions::-webkit-scrollbar {
    display: none;
}

.subTitle {
    display: inline-block;
    background-color: #EBF2F5;
    flex: 1;
    padding: 16px 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.slider {

    margin-left: 0;
    margin-right: 0;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    color: rgba(0, 0, 0, .85)
}