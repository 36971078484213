.myPostSwiper {
  padding-top: 57px;
}
.myPostSwiper .swiper {
  width: 100%;
  height: 100%;
}
.myPostSwiper .swiper, .myPostSwiper .swiper-container {
  overflow: visible !important;
}

.screenshotSlider {
  padding-top: 54px;
  margin-top: -37px;
}

.screenshotSlider .swiper-slide {
  width: auto !important;
}

.myPostSwiper .swiper-slide {
  width: 380px;
  /* height: 345px;
  border: 1px solid rgba(0, 0, 0, 0.28);
  border-radius: 20px;
  font-size: 18px;
  background-color: antiquewhite;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.myPostSwiper .swiper-pagination {
  bottom: -5px !important; /* Adjust the bottom position */
  left: 50% !important; /* Center horizontally */
  transform: translateX(-50%); /* Center alignment */
}

.myPostSwiper .swiper-pagination-bullet {
  background-color: transparent !important; /* Custom bullet color */
  width: 12px !important;
  height: 12px !important;
  opacity: 1; /* Full opacity */
  border: 1px solid #7283A4 !important;
}

.myPostSwiper .swiper-pagination-bullet-active {
  width: 12px !important;
  height: 12px !important;
  background-color: #7283A4 !important; /* Custom active bullet color */
}