.welcome-post .width-282 {
    width: 282px;
}

.welcome-post .font-16 {
    font-size: 16px;
}

.welcome-post .text-height-25 {
    line-height: 25px;
}   

.welcome-post .font-20 {
    font-size: 20px;
}

.welcome-post .text-height-30 {
    line-height: 30px;
}   