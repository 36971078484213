.avatar .xx-large {
    width: 9.375rem;
    max-width: 9.375rem;
    height: 9.375rem;
    max-height: 9.375rem;
    border-radius: 60px;
}

.avatar .x-large {
    width: 8rem;
    max-width: 8rem;
    height: 8rem;
    max-height: 8rem;
    border-radius: 52px;
}

.avatar .large {
    width: 4.375rem;
    max-width: 4.375rem;
    height: 4.375rem;
    max-height: 4.375rem;
    border-radius: 25px;
}

.avatar .medium {
    width: 3.0625rem;
    max-width: 3.0625rem;
    height: 3.0625rem;
    max-height: 3.0625rem;
    border-radius: 20px;
}

.avatar .small {
    width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    border-radius: 15px;
}

.avatar .x-small {
    width: 1.875rem;
    max-width: 1.875rem;
    height: 1.875rem;
    max-height: 1.875rem;
    border-radius: 12px;
}

.avatar .xx-small {
    width: 1.125rem;
    max-width: 1.125rem;
    height: 1.125rem;
    max-height: 1.125rem;
    border-radius: 7px;
    display: flex;
}

.avatar .profile-match {
    width: 5.625rem;
    max-width: 5.625rem;
    height: 5.625rem;
    max-height: 5.625rem;
    border-radius: 35px;
}

.avatar .edit-profile-avatar {
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
    max-height: 5rem;
    border-radius: 35px;
}

@media screen and (min-device-width: 300px) and (max-device-width: 768px) { 
   
    .avatar .xx-large {
      width:5rem;
      max-width:5rem;
      height:5rem;
      max-height:5rem;
      border-radius: 32px;
    }

    .avatar .profile-match {
        width: 4rem;
        max-width: 4rem;
        height: 4rem;
        max-height: 4rem;
        border-radius: 25px;
    }

  }