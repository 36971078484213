.btn-bg {
    background: #DFE5EB;
}

.pl-pr {
    padding: 0 1.25rem;
}

.drodown-shadow {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}