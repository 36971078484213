.crop-container .reactEasyCrop_Container {
  border-radius: 10px;
}

.crop-container .reactEasyCrop_CropAreaRound {
  border-radius: 135px;
}

.profile-cover-crop .reactEasyCrop_Container {
  border-radius: 10px;
}
