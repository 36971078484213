.contact-app-wrapper.virtual.mem.component {
    border: 1px solid #ffffff;
    background-color: var(--transparent);
    box-shadow: none;
    border-radius: 8px;
    flex-flow: column;
    min-height: 700px;
    margin-bottom: 80px;
}

.contact-app-wrapper.virtual.mem {
    overflow: hidden;
}

.contact-app-wrapper.virtual.mem {
    border-radius: 0;
    justify-content: space-between;
    margin-top: 0;
    display: flex;
    position: static;
}

.contact-app-wrapper {
    z-index: 0;
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    align-items: center;
    padding: 78px 120px 192px;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
}

.contact-app-wrapper.virtual {
    z-index: 200;
    color: #fff;
    background-color: #111;
    background-image: none;
    align-items: flex-start;
    margin-top: 15px;
    margin-right: 0;
    padding: 48px 40px 49px;
}

.size-50 {
    font-size: 50px;
    font-weight: 700;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.contact-col-1 {
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
}

.conatct-img {
    max-width: 474px;
    border: 0;
    vertical-align: middle;
}

.contact-form-block-2 {
    margin-bottom: 0;
}

.contact-form-embed {
    width: 550px;
    margin-top: 10px;
    margin-bottom: 0;
}

