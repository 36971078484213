:root {
  --animation-ms: 300ms;
  --animation-delay-ms: 300ms;
}

.animate-emoji {
  --_animation-name: default;
  animation: var(--_animation-name) 1s linear infinite alternate;
}

.animate-rotate {
  --_animation-name: rotateAnimation;
}
.animate-emoji:hover {
  --_animation-name: default;
}

.animate-grow {
  --_animation-name: growAnimation;
}

.animate-mount {
  animation: mount 0.2s linear;
}

.animate-unmount {
  animation: unmount 0.5s ease-in-out;
}

.animate-fadeIn {
  animation: fadeIn 0.4s ease-in;
}

.animtation-delay {
  animation-delay: 100ms;
}

.swing-image {
  animation: swinging 2s ease-in-out forwards infinite;
}

@keyframes rotateAnimation {
  25% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}

@keyframes growAnimation {
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.8);
  }
}

@keyframes mount {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes unmount {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes swinging{
  0% {
    transform: rotate(15deg);
  }
  50% { 
    transform: rotate(-15deg)
  }
  100% { 
    transform: rotate(15deg);
  }
}