body {
    font-family: "Poppins";
    font-weight: normal;
  }
  .p-title {
    color: #172746;
    background-color: #edf2f5;
  }
  .p-section-title {
    font-size: 24px;
    line-height: 35px;
    color: #3c3c3c;
  }
  .section-bg {
    background-color: #f9f8fa;
  }
  .pt-56 {
    padding-top: 56px;
  }
  .mt-40p {
    margin-top: 40px;
  }
  .mb-28p {
    margin-bottom: 28px;
  }

  .mt-28p {
    margin-top: 28px;
  }
  .mr-7rem {
    margin-right: 7rem;
  }
  .p-text {
    font-size: 16px;
    line-height: 25px;
    color: #3c3c3c;
    font-weight: 300;
  }
  .link-color {
    color: #285cb2;
  }
  .info-box {
    background-color: #edf2f5;
  }
  .info-box-bulb {
    width: 24px;
    height: 24px;
  }
  .font-500 {
    font-weight: 500;
  }
  .navbarlink {
    font-size: 14px;
    letter-spacing: 0.025em;
    line-height: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-right: 2rem;
    color: #203c6e;
    font-weight: 300;
  }
  .navbarActivelink {
    font-size: 14px;
    letter-spacing: 0.025em;
    line-height: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-right: 2rem;
    font-weight: 500;
    color: rgb(40, 92, 178);
    border-bottom: 2px solid rgb(40, 92, 178);
  }
  .createEvent {
    width: 166px;
    height: 30px;
    background: transparent;
    color: rgb(23, 39, 70);
    font-family: Poppins;
    font-size: 13px;
    border-radius: 20px;
    padding: 0.25rem;
    border: 2px solid #4b5563;
    display: flex;
  text-align: center;
  justify-content: center;
  }
  .navbarBox {
    padding-top: 0.5rem;
    padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px;
  position: relative;
  }
  .loginButton {
    font-size: 14px;
    font-weight: 500;
    color: rgb(23 39 70);
  }
  .joinButton {
    width: 88px;
    height: 30px;
    color: rgb(11, 18, 33);
    font-family: 'Poppins';
    font-size: 13px;
    border-radius: 20px;
    line-height: 1.5rem;
    font-weight: 300;
    background-color: rgb(255 174 57);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 23px;
  }
  .joinButton:hover {
    background-color: rgb(255 151 0);
  }
  .mainNavbarBox {
    height: 53px;
    box-shadow: rgba(213, 217, 226, 1) 0px 5px 15px;
    position: relative;
    z-index: 100;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .mainNavbarBoxContainer {
    width: 100%;
    max-width: 1067px;
    margin: 0 auto;
  }