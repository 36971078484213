
.navbar {
    background-color: #333;
    color: white;
    position: absolute;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 10000000;
  }

  .stepper-navbar {
    /* background-color: #111; */
    color: white;
    position: absolute;
    /* padding: 1rem;
    padding-top: 1rem; */
    /* padding-bottom: 0.5rem; */
    display: flex;
    width: 100%;
    right: 0px;
    top: 0px;
    z-index: 10000000;
  }

  .bottom-banner {
    background-color: #333;
    color: white;
    position: absolute;
    width: 100%;
    bottom:0px;
    right: 0;
    z-index: 10000000;
  }

  .chrome-style {
    bottom: 0px !important;
  }



  @media (max-width: 900px) {
    .bottom-banner {
        width: 100%;
        height: auto; 

    }
}

/* Apply styles only to Safari */
@supports (-webkit-appearance: none) {
  /* Styles will only apply if Safari-specific property is supported */
  .bottom-banner {
    bottom: 0;
  }
}

@media (max-width: 768px) {
  .bottom-banner {
    bottom:0px;

  }
}

  .bottom-button {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    max-width: 70%;
    background-color: "rgb(0, 240, 193)";
    border-radius: 4px;
    height: 55;
    
  }

  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
    z-index: 1; /* Ensure the loader is on top of the Avatar */
    border-radius: 35px;
  }
  
  .bottom-button-content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
  
  .bottom-button-prev {
    opacity: 0.9;
    filter: grayscale(0.15);
    cursor: default;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px 0 0 4px;
  }
  
  .bottom-button-next {
    cursor: pointer;
    filter: grayscale(0);
  }
  
  .bottom-button-next-button {
    padding: 0.6em 1.25em;
    font-size: 95%;
    overflow: hidden;
    text-shadow: none;
    cursor: pointer;
  }
  
  
  
  
  
  

  /* .bottom-banner {
    background-color: #333;
    color: white;
    position: absolute;
    padding: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    left: 0px;
    bottom: 0px;
    z-index: 10000000;
    /* display: flex; */
    /* justify-content: space-around; */
  /* } */

  .bottom-button {
    margin-right: 1px;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    max-width: 70%;
  }

  .bottom-button-prev {
    opacity: .9;
    filter: grayscale(.15);
    cursor: default;
    border-right: 1px solid rgba(0,0,0,.1);
    border-radius: 4px 0 0 4px;
  }

  .bottom-buttom-next {
    cursor: pointer;
    filter: grayscale(0);
  }

  .bottom-buttom-next-button {
    padding: 0.6em 1.25em;
    font-size: 95%;
    overflow: hidden;
    text-shadow: none;
    cursor: pointer;
  }
  
  .navbar a {
    flex-direction: none;
    color: white;
  }

  .qrCode {
    /* display: flex; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2;
  }

  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      align-items: center;

    }
    /* .qrCode {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2;
      
    } */

  }

  @media (min-width: 768px) {
    .qrCode {
      /* Styles for mobile devices */
      flex-direction: column;
    }
  }