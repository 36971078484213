.pip {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 200px;
    height: 150px;
    z-index: 9999;
    border: 1px solid #ccc;
    background: #2f2f2f;
    box-shadow: 0px 0px 8px 5px rgb(0 0 0 / 25%);
    border-radius: 0.75rem;
  }