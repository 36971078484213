.range__input {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 100%; */
  -webkit-appearance: none;
  appearance: none;
  height: 3px;
  background: rgba(114, 131, 164, 1);
  background-image: linear-gradient(#FF6565, #f03);
  background-repeat: no-repeat;
  margin-top: -1px;
}
.range__input:focus {
  outline: none;
}
.range__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #f03;
  cursor: pointer;
  margin-top: 0px;
}
.range__input::-moz-range-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #f03;
  border: none;
  cursor: pointer;
  margin-top: 0px;
}
.range__input::-ms-thumb {
  -webkit-appearance: none;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #f03;
  cursor: pointer;
  margin-top: 0px;
}
.range__input::-webkit-slider-runnable-track {
  appearance: none;
  height: 10px;
  cursor: pointer;
  background: transparent;
}
.range__input::-moz-range-track {
  appearance: none;
  height: 10px;
  cursor: pointer;
  background: transparent;
}
.range__input::-ms-track {
  appearance: none;
  height: 10px;
  cursor: pointer;
  background: transparent;
}

.volume__range {
  -webkit-appearance: none;
  height: 6px;
  background: rgba(255, 255, 255, 20%);
  background-image: linear-gradient(#ffffff,#ffffff);
  background-repeat: no-repeat;
  width: 65px;
  border-radius: 3px;
}
.volume__range:focus {
  outline: none;
}
.volume__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  margin-top: -3px;
}
.volume__range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  background-color: #7283a4;
  cursor: pointer;
  margin-top: -3px;
}
.volume__range::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7283a4;
  cursor: pointer;
  margin-top: -3px;
}
.volume__range::-webkit-slider-runnable-track {
  appearance: none;
  height: 6px;
  cursor: pointer;
  background: transparent;
}
.volume__range::-moz-range-track {
  appearance: none;
  height: 6px;
  cursor: pointer;
  background: transparent;
}
.volume__range::-ms-track {
  appearance: none;
  height: 6px;
  cursor: pointer;
  background: transparent;
}

/* svg path {
  fill: #7283a4;
}
svg rect {
  fill: #7283a4;
} */
.disabled svg:hover{
  fill: #edf2f5;
}

.active svg:hover path {
  fill: #203C6E;
}

.active svg:hover rect {
  fill: #203C6E;
} 
.expo svg path {
  fill: #203C6E;
}

.expo svg rect {
  fill: #203C6E;
} 

/* .sessions svg path {
  fill: #7283a4;
} */
.sessions svg g rect {
  fill: transparent;
}
.sessions svg rect {
  fill: #7283a4;
}
.sessions svg:hover g rect {
  fill: transparent;
}
.sessions svg:hover rect {
  fill: #203C6E;
}