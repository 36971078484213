.range-slider .slider {
  width: 100%;
  height: 5px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #FFFFFF;
  border-radius: 5px;
  background-image: linear-gradient(#203C6E, #203C6E);
  /* background-size: 40% 100%; */
  background-repeat: no-repeat;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #203C6E;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

.range-slider input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

