@import "swiper/css";

  .eventHeroSlider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 3px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
  }

  .eventHeroSlider .swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background-color: white !important;
    border-radius: 50%;
  }

  .eventHeroSlider .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
    transform: scale(1);
    opacity: 1;
  }