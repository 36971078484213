.event-card-bulets .bullets {
    font-size: 0px;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    border: 1px solid #203C6E;
    margin-right: 5px;
}

.event-card-bulets .bullets:last-child {
    margin-right: 0px;
}

.event-card-bulets .bullets.active {
    background-color: #203C6E;
}

.slider {
    width: 300px;
    text-align: center;
    overflow: hidden;
  }
  
  .slides {
    display: flex;
    
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    
    
    
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
    /*
    scroll-snap-points-x: repeat(300px);
    scroll-snap-type: mandatory;
    */
  }
  .slides::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  .slides::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  .slides::-webkit-scrollbar-track {
    background: transparent;
  }
  .slides > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 100px; */
  }
  /* .slides > div:target {
    transform: scale(0.8);
  } */