.event-card-single {
    box-shadow: 1px 1px 4px rgb(0 0 0 / 16%);
    border-radius: 10px;
    width: 218px;
}

.event-card-single .event-card-single-cover {
    height: 102px;
}

.event-card-single .event-card-single-text {
    min-height: 92px;
}

.event-card-single .event-date-time {
    color: #203C6E;
}

.event-card-single .event-title {
    color: #172746;
}