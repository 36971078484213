.box-menu-link {
    display: inline-block;
    /* padding: 20px 15px; */
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-decoration: none;
    transition: text-decoration 0.3s ease-in-out;
    font-family: 'Lato', sans-serif;
  }
  
.box-menu-link:hover {
text-decoration: underline;

}

.see-event-btn {
    width: 180px;
    height: 48px;
    background-color: #ffffff;
    border-radius: 24px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.see-event-btn:hover {
    background-color:#f0f8fd ;
    color: #0061d5;
}

.mySwiperBox {
    width: 100%;
    height: 327px;
  }

  .mySwiperBox .swiper-pagination-clickable .swiper-pagination-bullet {
    background-color: white;
    opacity: .9;
  }

  .mySwiperBox .swiper-pagination-bullet-active-next, .mySwiperBox .swiper-pagination-bullet-active-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiperBox .swiper-pagination-bullet-active-next-next, .mySwiperBox .swiper-pagination-bullet-active-prev-prev {
    width: 14px;
    height: 14px;
    background-color: white;
  }

  .mySwiperBox .swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    background-color: #013370 !important;
  }

  .mySwiperBox .swiper-fade .swiper-slide {
    height: 327px;
  }

  .mySwiperBox .swiper-slide img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .bold-lato-font {
    font-family: 'Lato', sans-serif !important;
    font-weight: 700 !important;
  }

  .normal-lato-font {
    font-family: 'Lato', sans-serif !important;
    font-weight: 400 !important;
  }

  .footer-social-hover svg {
    color: #8ea6b2;
  }

  .footer-social-hover:hover svg {
    color: #ffffff;
  }
  .pdf-image-viewer {
    position: relative;
  }
  
  /* Position the Swiper pagination at the bottom */
  .swiper-pagination {
    position: absolute;
    left: 10%;
    top: 62%;
    bottom: 0px;
    /* transform: translateX(-50%); */
    z-index: 10;
    text-align: center;
  }
  
  /* Style for the current pagination fraction */
  .swiper-pagination-current,
  .swiper-pagination-total {
    font-size: 16px;
    color: #000;
  }
  
  /* Style for the bullet (dot) */
  .swiper-pagination-bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 50%;
    margin: 0 4px;
    /* position: absolute; */
  }
  
  /* When the bullet is active (for clickable pagination) */
  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  
  /* Fullscreen icon button style */
  .fullscreen-toggle {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  /* Accordion Parse Content UL style */
  .parseCustom-ul {
    list-style-type: disc;
    padding-left: 1.25rem; /* Tailwind class 'pl-5' equivalent */
  }
  
  .parseCustom-ul li::marker {
    color: white !important;
  }

  .parseCustom-ol {
    list-style-type: auto;
    padding-left: 1.25rem; /* Tailwind class 'pl-5' equivalent */
  }
  
  .parseCustom-ol li::marker {
    color: white !important;
  }